<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <TemplateTreeList :type="'check'" :list-data="listData1" :selectValue="listSelect2" @change="listChange"></TemplateTreeList>
          <div class="box-div">
              <div class="title">临床表现
                  <div style="display: flex;    align-items: baseline;" @click="userOpen1()">
                      <div v-if="selectValue1 !== ''">
                          {{selectValue1}}
                      </div>
                      <div style="color: #A6A6A6" v-else>
                          请选择
                      </div>
                      <!--        <div>-->
                      <van-icon name="arrow" style="margin-left: 5px" color="#A7A7A7" size="17px"/>
                      <!--        </div>-->
                  </div>
              </div>
          </div>
          <div class="box-div">
              <div class="title">症状持续时间
                  <div style="display: flex;    align-items: baseline;" @click="userOpen2()">
                      <div v-if="selectValue2 !== ''">
                          {{selectValue2}}
                      </div>
                      <div style="color: #A6A6A6" v-else>
                          请选择
                      </div>
                      <!--        <div>-->
                      <van-icon name="arrow" style="margin-left: 5px" color="#A7A7A7" size="17px"/>
                      <!--        </div>-->
                  </div>
              </div>
          </div>
          <TemplateTreeList :type="'check'" :list-data="listData2" :selectValue="listSelect2" @change="listChange"></TemplateTreeList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
            </div>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>{{countText}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults :countText="countText" :countTextList="countTextList" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker1" position="bottom">
      <van-picker
          title="临床表现"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm1"
          @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker
          title="症状持续时间"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm2"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateTreeList from '@/components/Template3/TemplateTreeList';
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextList:[],
        listSelect: [],
      listSelect1: [],
      listSelect2: [],
      listData1: [
        {
          title: '年龄≥60岁',
          // content: '健康，不吸烟，不喝酒或少量饮酒'
         
        },
        {
          title: '高血压',
           tips:"发作时血压≥140/90mmHg"
          // content: '轻微疾病，无实质性功能限制。例如(包括，但不限于):当前吸烟者、社交饮酒者、怀孕、30<BMI<40、控制良好的 DM/HTN、轻微肺部疾病'
        },
        {
          title: '糖尿病',
          // content: '实质性的功能限制。一种或多种中度至重度疾病。例如(包括，但不限于):控制不良的 DM 或 HTN、COPD、病态肥胖(BMI≥40)、活动性肝炎、酒精依赖或酗酒、起搏器植入、射血分数中度降低、ESRD 定期透析、早产儿 PCA<60周，3个月之前MI、CVA、TIA 或 CAD /支架病史'
        },
      ]
        ,listData2: [
          {
              title: '再发TIA(7天内第2次发作)',
              // content: '例如(包括，但不限于):近期(<3个月)MI、CVA、TIA或 CAD/支架病史。进行性心肌缺血或严重瓣膜功能障碍、严重的射血分数降低、败血症、DIC、ARD、ESRD未定期透析。'
          }
          ,
          {
              title: '同侧颈内动脉狭窄≥50%',
              // content: '例如(包括，但不限于):近期(<3个月)MI、CVA、TIA或 CAD/支架病史。进行性心肌缺血或严重瓣膜功能障碍、严重的射血分数降低、败血症、DIC、ARD、ESRD未定期透析。'
          }
          ,
          {
              title: 'MRI弥散序列呈现高信号',
              // content: '例如(包括，但不限于):近期(<3个月)MI、CVA、TIA或 CAD/支架病史。进行性心肌缺血或严重瓣膜功能障碍、严重的射血分数降低、败血症、DIC、ARD、ESRD未定期透析。'
          }
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue:'',
      selectValue1: '',
      selectValue2: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker1: false,
      showPicker2: false,
      columns:[],
      columns1: ['肌无力', '言语障碍不伴无力', '单侧无力'],
      columns2: ['<10min', '10-59min', '≥60min'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      score:0
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateTreeList,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    listChange (result) {
      this.listSelect = result
      this.show=false
        console.log(result)
    },
    coumputTetxt () {
      this.show=true
        //
        this.listSelect = this.listSelect.filter((item1)=>!this.columns1.some((item2)=>item1 === item2))
        this.listSelect = this.listSelect.filter((item1)=>!this.columns2.some((item2)=>item1 === item2))
        if (this.selectValue1 !== ''){
            this.listSelect.push(this.selectValue1)
        }
        if (this.selectValue2 !== ''){
            this.listSelect.push(this.selectValue2)
        }
        // eslint-disable-next-line no-unused-vars
        let i = 0;
      if (this.listSelect.includes("年龄≥60岁")) i++
      if (this.listSelect.includes("高血压")) i++
      if (this.listSelect.includes("糖尿病")) i++
      if (this.listSelect.includes("再发TIA(7天内第2次发作)")) i = i+2
      if (this.listSelect.includes("同侧颈内动脉狭窄≥50%")) i = i+2
      if (this.listSelect.includes("MRI弥散序列呈现高信号")) i=i+2
      if (this.listSelect.includes("言语障碍不伴无力记")) i++
      if (this.listSelect.includes("单侧无力记")) i = i+2
      if (this.listSelect.includes("10-59min")) i++
      if (this.listSelect.includes("≥60min")) i = i +2

      if (i <=3 ){
          this.countText = '低危'
      }else if(i <= 7) {
          this.countText = '中危'
      }else if(i<=13){
          this.countText = '高危'
      }
      this.score = i
      this.result = this.countText
      this.countText = `${this.score}分 ${this.result}`
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'OOWCARQK',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.listSelect1 = res.data.content.value
          this.listSelect2 = res.data.content.value
          this.listSelect = res.data.content.value
          for(var item of this.columns1){
              for (var da of res.data.content.value){
                  if (item === da){
                      this.selectValue1 = item
                      break
                  }
              }
          }
          for(var item1 of this.columns2){
                for (var da1 of res.data.content.value){
                    if (item1 === da1){
                        this.selectValue2 = item1
                        break
                    }
                }
          }
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.coumputTetxt()
        }
      }
    },
    async submit () {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }

      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.score,
          result: this.result,
          DisplayResults:this.countText
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm1(value) {
        //删除存在的colum1中的数据
      // this.listSelect = this.listSelect.filter((item1)=>!this.columns1.some((item2)=>item1 === item2))
      // this.listSelect.push(value)
      this.selectValue1 = value
      this.showPicker1 = false
    },
    onConfirm2(value) {
      // this.listSelect = this.listSelect.filter((item1)=>!this.columns2.some((item2)=>item1 === item2))
      // this.listSelect.push(value)
      this.selectValue2 = value
      this.showPicker2 = false
    },
    onCancel() {
      this.showPicker1 = false
      this.showPicker2 = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
    userOpen1(){
        this.columns = this.columns1,
        this.showPicker1 = true
        this.show=false
    },
    userOpen2(){
        this.columns = this.columns2,
        this.showPicker2 = true
        this.show=false
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
  display: flex;
  justify-content: space-between;
}
</style>
